<template>
    <div class="template-page practical-info">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <div v-if="pageData.body!=undefined" v-html="this.pageData.body[0].processed"></div>
            <iframe frameborder="0"
            height="600"
            scrolling="no"
            src="https://calendar.google.com/calendar/embed?src=47011clv2n6vh17dt995qbhbbg%40group.calendar.google.com&amp;ctz=Europe/Rome" style="border: 0" width="800"></iframe>
        </basic-page>
    </div>
</template>

<script>
import { fetchSingleNode } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'

export default {
  components: { BasicPage },
    name: 'information-for-students',
    data: () => {
        return {
            currentPageId: "0321bb6a-e57c-4ffd-abb1-c376b4a9062c",
            pageData: {},
            fulldata: {},
            bannerimg: null
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] })
            .then(res => {
                this.pageData = res[0].attributes
                this.fulldata = res[0]
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";


</style>